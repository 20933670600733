@import "../../../../assets/styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.userlist {
  &_title {
    font-family: $font-family-display;
    font-style: normal;
    font-weight: 600;
    font-size: 30px !important;
    line-height: 36px !important;
    color: $text !important;
    padding-bottom: 12px;
    @media only screen and (max-width: 576px) {
      font-size: 24px !important;
    }
  }
  &_btn {
    display: flex;
    justify-content: end;
    &_modify_selected {
      &:not(:disabled) {
        background-color: $primary;
        color: #000000 !important;
      }
    }
  }
  &_table {
    table {
      text-align: center;
      border: none;
    }
    .ant-table-thead {
      > tr {
        > th {
          text-align: center;
          border-bottom: none;
        }
      }
    }
    .ant-table-tbody {
      > tr {
        > td {
          border-bottom: none;
        }
      }
    }
    .ant-table-pagination {
      &.ant-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 32px 0 0;
        column-gap: 2px;
        @media only screen and (max-width: 576px) {
          margin-top: 10px;
          height: 45px;
        }
        .ant-pagination-prev {
          margin-right: auto;
          display: flex;
          align-items: center;
          border: none;
          @media only screen and (max-width: 576px) {
            margin-top: 100px;
            position: absolute;
            left: 0;
          }
          &.ant-pagination-disabled {
            .prev {
              border-color: transparent;
              background-color: $secondary;
              cursor: no-drop;
              &:hover {
                border-color: transparent !important;
              }
            }
          }
        }
        .ant-pagination-next {
          order: 3;
          margin-left: auto;
          display: flex;
          align-items: center;
          border: none;
          @media only screen and (max-width: 576px) {
            margin-left: auto;
            margin-top: 100px;
            position: absolute;
            right: 0;
          }
          &.ant-pagination-disabled {
            .next {
              border-color: transparent;
              background-color: $secondary;
              cursor: no-drop;
              &:hover {
                border-color: transparent !important;
              }
            }
          }
        }
        .ant-pagination-item {
          border: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          font-family: $font-family-text;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          height: 35px;
          margin: 0;
          border-radius: 8px;
          @media only screen and (max-width: 576px) {
            min-width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
          }
          &.ant-pagination-item-active {
            background-color: $secondary;
            &:hover a {
              color: $text;
            }
          }
        }
      }
    }
  }
  &_viewaction {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 16px;
    font-family: $font-family-text;
    text-decoration: unset;
  }
  a:hover {
    color: #000000;
  }
  &_permissionlist {
    &_item {
      padding: 0 !important;
      border-block-end: 0px solid transparent !important;
      &_meta {
        h4 {
          color: #000000 !important;
          font-size: 12px !important;
          font-family: $font-family-text;
        }
      }
    }
  }
  &_custom_pagination_btn {
    padding: 8px 15px;
    font-family: $font-family-text;
    font-weight: 500 !important;
    border: none;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:disabled) {
      color: $text !important;
    }
  }
}
