@import "../../../../assets/styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.adjust_role {
  &_wrap {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 576px) {
      align-items: baseline;
    }
    &_delete_role {
      float: right;
      font-family: $font-family-text;
      font-size: 16px;
      font-weight: 400;
      color: $text;
      line-height: 15px;
    }
  }
  &_title {
    font-family: $font-family-display;
    font-style: normal;
    font-weight: 600;
    font-size: 30px !important;
    line-height: 36px !important;
    color: $text !important;
    padding-bottom: 12px;
    @media only screen and (max-width: 576px) {
      font-size: 24px;
    }
  }
  &_dropdown {
    width: 202px !important;
    background: #ffffff;
    @media only screen and (max-width: 576px) {
      width: 100% !important;
    }
  }
  &_textarea {
    border-radius: 8px;
    border: 1px solid #a8a8a8;
    background: #ffffff;
  }
  &_permission_title {
    font-family: $font-family-display;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px !important;
    color: $text !important;
    margin-top: 22px;
    margin-bottom: 5px !important;
  }
  &_permission_name {
    font-family: $font-family-text;
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 150%;
    color: $text;
  }

  &_custombutton {
    font-size: 16px;
    font-family: $font-family-text;
    font-weight: 400;
    &:hover {
      border-color: $accent !important;
    }
  }
}
