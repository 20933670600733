@import "../../../../assets/styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.create_role {
    &_title {
        font-family: $font-family-display;
        font-style: normal;
        font-weight: 600;
        font-size: 30px !important;
        line-height: 36px !important;
        color: $text !important;
        padding-bottom: 12px;
        @media only screen and (max-width: 576px) {
            font-size: 24px !important;
        }
    }
    &_permission_title {
        font-family: $font-family-display;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px !important;
        color: $text !important;
        margin-top: 27px;
    }
    &_permission_name {
        font-family: $font-family-text;
        font-style: normal;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 150%;
        color: #000000;
    }
}
