@font-face {
  font-family: "SF-Pro-Display";
  src: local("SF-Pro-Display"),
    url("/src/assets/fonts/SF-Pro-Display-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SF-Pro-Text";
  src: local("SF-Pro-Text"),
    url("/src/assets/fonts/SF-Pro-Text-Regular.ttf") format("truetype");
}

:root {
  --font-SF-Pro-Display: "SF-Pro-Display";
  --font-SF-Pro-Text: "SF-Pro-Text";
  --color-text: #1a1419;
  --color-background: #fdfcfd;
  --color-primary: #b8c7b9;
  --color-secondary: #f2eef1;
  --color-accent: #50834b;
}

.ant-layout {
  margin: 0;
  font-family: var(--font-SF-Pro-Text);
  font-style: normal;
  font-weight: normal;
  height: auto;
}

.main-content {
  min-height: calc(100vh - 80px - 69px);
  display: flex;
  flex-direction: column;
  background: var(--color-background);
}

.page_layout {
  margin-top: 28px;
  margin-bottom: 16px;
  @media only screen and (max-width: 576px) {
    margin: 16px;
  }

  @media (max-width: 767.98px) {
    margin: 16px;
  }
}

/* Input / TextArea - Start */
[class*=" ant-input"] {
  font-family: var(--font-SF-Pro-Text);
  font-style: normal;
  font-weight: 400;
}
[class*=" ant-input"]:not(.ant-input-status-error):not(
    .ant-input-affix-wrapper-status-error
  ):not(.ant-input-number-readonly):hover {
  border-color: var(--color-accent) !important;
}
[class*=" ant-input"]:not(.ant-input-status-error):not(
    .ant-input-affix-wrapper-status-error
  ):not(.ant-input-number-readonly):active {
  border-color: var(--color-accent) !important;
}
[class*=" ant-input"]:not(.ant-input-status-error):focus {
  border-color: var(--color-accent) !important;
  box-shadow: 0 0 0 2px rgba(80, 131, 75, 0.1);
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
  border-color: var(--color-accent);
}

.ant-form-item-label {
  position: relative;
  top: 8px;
  padding: 2px 4px !important;
  background: #ffffff;
  border-radius: 4px;
  left: 29px;
  font-family: var(--font-SF-Pro-Text);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  width: fit-content !important;
  z-index: 1;
}

.ant-form-item-control-input .ant-input {
  background: #ffffff;
  border: 1px solid #a8a8a8;
  border-radius: 8px;
  height: 41px;
  font-size: 16px;
}
.ant-form-item-control-input .ant-input:not(.ant-input-status-error):hover {
  border-color: var(--color-accent) !important;
}
.ant-form-item-control-input .ant-input:not(.ant-input-status-error):focus {
  border-color: var(--color-accent) !important;
  box-shadow: 0 0 0 2px rgba(80, 131, 75, 0.1);
}

.ant-form-item-control-input textarea.ant-input {
  background: #ffffff;
  border: 1px solid #a8a8a8;
  border-radius: 8px;
  height: auto;
  font-size: 16px;
}
.ant-form-item-control-input
  textarea.ant-input:not(.ant-input-status-error):hover {
  border-color: var(--color-accent) !important;
}
.ant-form-item-control-input
  textarea.ant-input:not(.ant-input-status-error):focus {
  border-color: var(--color-accent) !important;
  box-shadow: 0 0 0 2px rgba(80, 131, 75, 0.1);
}

.ant-form-item-explain {
  font-family: var(--font-SF-Pro-Text);
  font-style: normal;
  font-weight: 400;
}
.ant-input-affix-wrapper > input.ant-input {
  background: #ffffff;
  height: auto !important;
  font-size: 16px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid var(--color-accent);
}

.ant-input-number-focused:not(.ant-input-number-readonly) {
  border-color: var(--color-accent);
  box-shadow: 0 0 0 2px rgba(80, 131, 75, 0.1);
}

.ant-input-number .ant-input-number-input {
  text-align: center;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

/* Input / TextArea - End */

/* Checkbox - Start */
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: var(--color-text);
  border-color: var(--color-text);
  animation: none;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)::after {
  border-color: var(--color-text);
  animation: none;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner {
  border-color: var(--color-text);
  animation: none;
}
.ant-checkbox .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
  outline: 4px solid var(--color-secondary);
  outline-offset: 1px;
  transition: outline-offset 0s, outline 0s;
}
.ant-checkbox .ant-checkbox-inner {
  border: 1px solid var(--color-text);
  border-radius: 0;
  width: 18px;
  height: 18px;
}
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: var(--color-text);
  border-color: var(--color-text);
}
.ant-checkbox-checked::after {
  border: none;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-text);
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--color-text);
}
.ant-checkbox-checked .ant-checkbox-inner:after {
  transform: rotate(45deg) scale(1.2) translate(-50%, -50%);
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: var(--color-text);
}
.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: #ffffff;
}
/* Checkbox - End */

/* Table - Start */
.ant-table-wrapper .ant-table {
  background: transparent;
  color: #000;
  font-size: 16px;
  font-family: var(--font-SF-Pro-Text);
}
.ant-table-wrapper .ant-table-thead > tr > th {
  color: var(--color-text);
  font-size: 16px;
  font-family: var(--font-SF-Pro-Display);
  font-weight: 600;
  background: transparent;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: transparent;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 9px 16px;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: transparent;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background-color: transparent;
}
.ant-table-wrapper tr.ant-table-expanded-row > td {
  background: var(--color-background);
}
.ant-table-wrapper tr.ant-table-expanded-row > th,
.ant-table-wrapper tr.ant-table-expanded-row:hover > th,
.ant-table-wrapper tr.ant-table-expanded-row > td,
.ant-table-wrapper tr.ant-table-expanded-row:hover > td {
  background: var(--color-background);
}
/* Table - End */

/* Button - Start */
.ant-btn-default {
  border-color: var(--color-accent);
  color: var(--color-accent);
  font-family: var(--font-SF-Pro-Text);
}
.ant-btn:not(:disabled):focus-visible {
  outline: none;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):not(
    .ant-btn-background-ghost
  ):hover {
  color: var(--color-accent);
  border-color: var(--color-accent);
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
  color: var(--color-accent);
  border-color: var(--color-accent);
}
.ant-btn-primary {
  background-color: var(--color-accent);
  font-family: var(--font-SF-Pro-Text);
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: var(--color-accent);
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
  background-color: var(--color-accent);
}
.ant-btn-default:disabled {
  border-radius: 8px;
  background: var(--color-secondary);
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-btn-default.ant-btn-loading:disabled {
  color: rgba(0, 0, 0, 0.45) !important;
  display: flex;
  align-items: center;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
  border-color: white;
  color: inherit;
}
.ant-btn:not(:disabled):focus-visible {
  outline: none;
}
/* Button - End */

/* Modal - Start */
.ant-modal .ant-modal-content {
  border-radius: 8px;
  border: 1px solid #a8a8a8;
  background: var(--color-secondary);
}
/* Modal - End */

/* Select - Start */
.ant-select,
.ant-select-selector,
.ant-select-selection-placeholder,
.ant-select-selection-search-input,
.ant-select-selection-item,
.ant-select-item-option-content {
  font-size: 16px;
  font-family: var(--font-SF-Pro-Text);
  font-style: normal;
}
.ant-select .ant-select-clear {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #a8a8a8;
  border-radius: 8px;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):not(.ant-select-status-error):hover
  .ant-select-selector {
  border-color: var(--color-accent);
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):not(.ant-select-status-error).ant-select-open:hover
  .ant-select-selector {
  border-color: var(--color-accent);
}
.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):not(.ant-select-status-error)
  .ant-select-selector {
  border-color: #a8a8a8;
  box-shadow: none;
}
/* Select - End  */

/* Multi Select - Start  */
.ant-select-multiple .ant-select-selection-item {
  background: var(--color-secondary);
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--color-background);
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: var(--color-accent);
}
/* Multi Select - End  */

/* DatePicket - Start */
.ant-picker:hover {
  border-color: var(--color-accent);
}
.ant-picker-focused {
  box-shadow: 0 0 0 2px rgba(184, 199, 185, 0.1);
}
.ant-picker-focused.ant-picker {
  border-color: var(--color-accent);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid var(--color-accent);
}
.ant-picker-dropdown .ant-picker-today-btn {
  color: var(--color-accent);
}
.ant-picker-dropdown .ant-picker-today-btn:hover {
  color: var(--color-primary);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background: var(--color-accent);
}
.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: var(--color-accent);
}
/* DatePicker - End */

/* Spinner - Start */
.ant-spin {
  bottom: 0;
  margin: auto;
  color: var(--color-accent);
}
/* Spinner - End */

/* Disabled - Start */
.disabled-events {
  opacity: 0.6;
  pointer-events: none;
}

.disabled {
  cursor: no-drop;
  opacity: 0.6;
}
/* Disabled - End */

/* Table Pagination - Start */
.ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: var(--color-accent);
}
.ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: var(--color-accent);
}
.ant-pagination:not(.ant-pagination-disabled)
  .ant-pagination-item:focus-visible {
  outline: none;
}
/* Table Pagination - End */

/* Table Column Sort - Start */
.ant-table-wrapper .ant-table-column-sorter-up,
.ant-table-wrapper .ant-table-column-sorter-down {
  font-size: 20px;
}

.ant-table-wrapper .ant-table-column-sorter-down.active {
  color: var(--color-accent);
}
.ant-table-wrapper .ant-table-column-sorter-up.active {
  color: var(--color-accent);
}
/* Table Column Sort - End */

/* Switch - Start */
.ant-switch.ant-switch-checked {
  background: var(--color-text);
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: var(--color-text);
}
.ant-switch:focus-visible {
  outline: none;
}
/* Switch - End */

/* Scrollbar - Start */
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #414141;
  border-radius: 5px;
}
/* Scrollbar - End */
