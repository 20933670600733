@import "../../../../assets/styles/colors.scss";
$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.delete_role {
  &_title_head {
    font-family: $font-family-display;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 17px !important;
  }
  &_description {
    margin-bottom: 31px !important;
    font-size: 16px;
    font-family: $font-family-text;
    color: $text;

    font-weight: 400;
  }
  &_title_text {
    font-family: $font-family-display;
    color: $text;
  }
  &_description_text {
    font-family: $font-family-text;
    font-size: 16px;
    color: $text;
  }
  &_btn_wrap{
    text-align: end;
  }
  &_userlist_btn {
    font-family: $font-family-text;
    font-weight: 400;
    border: none;
    &:hover {
      border-color: $accent !important;
    }
  }
  &_cancel_btn {
    font-family: $font-family-text;
    font-weight: 400;
    border: none;
    &:hover {
      border-color: $accent !important;
    }
  }
}
