@import "../../../assets/styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.adminchangeshistory {
  &_title {
    color: $text;
    font-family: $font-family-display;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 8px !important;
  }
  &_dropdown {
    width: 202px !important;
    background: #ffffff;
    margin-bottom: 10px;
    .ant-select-selector {
      display: flex;
      align-items: center;
    }
    @media only screen and (max-width: 576px) {
      width: 100% !important;
    }
  }
  &_table {
    &_cell_text {
      color: #000000;
      font-family: $font-family-text;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
    }
    .ant-table {
      &.ant-table-small {
        table {
          border: none;
        }
        .ant-table-thead {
          > tr {
            > th {
              text-align: center;
              border-bottom: none;
            }
          }
        }
        .ant-table-tbody {
          > tr {
            > td {
              border-bottom: none;
              font-size: 12px;
              font-weight: 400;
              padding: 6px 8px;
              &:first-child {
                min-width: 82px;
              }
              &:nth-child(2) {
                min-width: 60px;
              }
              &:not(:nth-child(4)) {
                text-align: center;
              }
              &:nth-child(4) {
                font-style: italic;
              }
              @media only screen and (max-width: 576px) {
                padding: 2px 0px;
                &:first-child {
                  min-width: 57px;
                }
                &:nth-child(2) {
                  min-width: 50px;
                }
              }
            }
          }
        }
      }
    }
    .ant-table-pagination {
      &.ant-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 32px 0 0;
        column-gap: 2px;
        @media only screen and (max-width: 576px) {
          margin-top: 10px;
          height: 45px;
        }
        .ant-pagination-prev {
          margin-right: auto;
          display: flex;
          align-items: center;
          border: none;
          @media only screen and (max-width: 576px) {
            margin-top: 100px;
            position: absolute;
            left: 0;
          }
          &.ant-pagination-disabled {
            .prev {
              border-color: transparent;
              background-color: $secondary;
              cursor: no-drop;
              &:hover {
                border-color: transparent !important;
              }
            }
          }
        }
        .ant-pagination-next {
          order: 3;
          margin-left: auto;
          display: flex;
          align-items: center;
          border: none;
          @media only screen and (max-width: 576px) {
            margin-left: auto;
            margin-top: 100px;
            position: absolute;
            right: 0;
          }
          &.ant-pagination-disabled {
            .next {
              border-color: transparent;
              background-color: $secondary;
              cursor: no-drop;
              &:hover {
                border-color: transparent !important;
              }
            }
          }
        }
        .ant-pagination-item {
          border: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          font-family: $font-family-text;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          height: 35px;
          margin: 0;
          border-radius: 8px;
          @media only screen and (max-width: 576px) {
            min-width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
          }
          &.ant-pagination-item-active {
            background-color: $secondary;
            &:hover a {
              color: $text;
            }
          }
        }
      }
    }
  }
  &_expanded {
    color: #000;
    font-family: $font-family-text !important;
    font-size: 12px !important;
    font-style: italic;
    font-weight: 400;
    @media only screen and (max-width: 576px) {
      margin-bottom: 16px;
      text-align: left;
    }
  }
  &_custom_pagination_btn {
    padding: 8px 15px;
    font-family: $font-family-text;
    font-weight: 500 !important;
    border: none;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:disabled) {
      color: $text !important;
    }
  }
}
