$font-family-text: "SF-Pro-Text";

.search {
  width: 100%;
  font-family: $font-family-text;
  .ant-select-arrow {
    font-size: 14px;
  }
  .ant-select-clear {
    width: 14px;
    height: 14px;
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
  }
  &_item {
    border: 1px solid #a8a8a8;
    border-radius: 8px;
    min-width: max-content !important;
    .narrow_down,
    .ant-select-item-empty {
      color: #000000;
      font-family: $font-family-text;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 11px;
      padding: 0;
    }
  }
}
