button.custom_btn {
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  font-family: "SF-Pro-Text";
  border: 1px solid #ffffff;
  &:hover {
    &:not(:disabled) {
      border-color: #ffffff !important;
    }
  }
  img {
    height: 35px;
    margin: 1px 0 5px 10px;
  }
}
