@import "../../../assets/styles/colors.scss";

$font-family-text: "SF-Pro-Text";

.ant-layout-header {
  background: $accent;
  height: 80px;
  font-family: $font-family-text;
  @media (max-width: 575.98px) {
    height: 64px;
  }
}

.img-logo {
  width: 100%;
  height: 70px;
  margin: 5px auto;
  @media (max-width: 575.98px) {
    height: 50px;
  }
}
.div-bar {
  border-left: 1px solid $background;
  height: 65px;
  width: 1px;
  margin: 0 10px 0 10px;
}
.div-text {
  font-family: $font-family-text;
  font-style: normal;
  font-size: 20px;
  font-weight: 600;
  color: $background;
  text-transform: uppercase;
  @media (max-width: 575.98px) {
    font-size: 16px;
    line-height: 1.2;
  }
}
.menu-link {
  font-family: $font-family-text;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: $background;
  text-decoration: auto;
  &:hover {
    color: $background;
  }
  &.active {
    font-weight: 700;
  }
}
.img-profilename {
  display: flex;
  align-items: center;
  .profile-name {
    font-family: $font-family-text;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: $background;
    margin: 0 5px 0 0;
  }
  .profile-name-case {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    font-family: "SF-Pro-Display";
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: $background;
    padding: 5px;
    border: 2px solid $background;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.btn-logout {
  font-family: $font-family-text;
  text-transform: uppercase;
  background: $secondary;
  color: $accent;
  &:hover {
    color: $accent !important;
    border-color: $accent !important;
  }
  &:not(:disabled) {
    &:focus-visible {
      outline: none;
    }
  }
}
.hamburger-btn {
  width: 35px;
  padding: 0;
  svg {
    color: $background;
  }
  &:hover {
    background-color: $secondary;
  }
  &:active {
    background-color: $secondary;
  }
  &:visited {
    background-color: $secondary;
  }
  .hamburger-icon {
    font-size: 25px;
  }
}
.menu-modal {
  .ant-modal-content {
    background: $accent !important;
  }
}
.ant-select-selection-item {
  div {
    span {
      font-weight: normal !important;
    }
  }
}
.reports_sub_menu_items {
  .ant-space {
    .ant-space-item {
      line-height: 10px;
    }
  }
  &_style {
    .ant-dropdown-menu {
      border: 1px solid #a8a8a8;
      padding: 7px 0px;
      li {
        &.ant-dropdown-menu-item {
          &:first-child {
            svg {
              path {
                fill: $accent;
              }
            }
            &:has(.active_item) {
              background-color: $accent;
              border-radius: 0;
              .ant-dropdown-menu-item-icon {
                svg {
                  path {
                    fill: $background;
                  }
                }
              }
            }
            &:hover {
              background-color: $accent;
              border-radius: 0;
              .ant-dropdown-menu-item-icon {
                svg {
                  path {
                    fill: $background;
                  }
                }
              }
            }
          }
          &:has(.active_item) {
            background-color: $accent;
            border-radius: 0;
            color: $background;
            .ant-dropdown-menu-item-icon {
              svg {
                path {
                  stroke: $background;
                }
              }
            }
          }
          padding: 4px 15px 4px 8px;
          &:hover {
            background-color: $accent;
            border-radius: 0;
            .ant-dropdown-menu-item-icon {
              svg {
                path {
                  stroke: $background;
                }
              }
            }
            .ant-dropdown-menu-title-content {
              .disabled-events {
                color: $background;
              }
            }
          }
          .ant-dropdown-menu-title-content {
            .text {
              color: #000000;
              font-family: $font-family-text;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              text-transform: capitalize;
              margin-left: 2px;
              &:hover {
                color: $background;
              }
              &.active_item {
                color: $background;
              }
            }
          }
        }
      }
    }
    .menu_icon {
      svg {
        height: 19px;
        width: 19px;
        path {
          stroke: $accent;
        }
      }
    }
  }
}
.admin_sub_menu_items {
  .ant-space {
    .ant-space-item {
      line-height: 10px;
    }
  }
  &_style {
    .ant-dropdown-menu {
      border: 1px solid #a8a8a8;
      padding: 7px 0px;
      li {
        &.ant-dropdown-menu-item {
          &:first-child {
            svg {
              path {
                fill: $accent;
              }
            }
            &:has(.active_item) {
              background-color: $accent;
              border-radius: 0;
              .ant-dropdown-menu-item-icon {
                svg {
                  path {
                    fill: $background;
                  }
                }
              }
            }
            &:hover {
              background-color: $accent;
              border-radius: 0;
              .ant-dropdown-menu-item-icon {
                svg {
                  path {
                    fill: $background;
                  }
                }
              }
            }
          }
          &:has(.active_item) {
            background-color: $accent;
            border-radius: 0;
            .ant-dropdown-menu-item-icon {
              svg {
                path {
                  stroke: $background;
                  fill: $background;
                }
              }
            }
          }
          padding: 4px 15px 4px 8px;
          &:hover {
            background-color: $accent;
            border-radius: 0;
            .ant-dropdown-menu-item-icon {
              svg {
                path {
                  stroke: $background;
                  fill: $background;
                }
              }
            }
            .ant-dropdown-menu-title-content {
              .disabled-events {
                color: $background;
              }
            }
          }
          .ant-dropdown-menu-title-content {
            .text {
              color: #000000;
              font-family: $font-family-text;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              text-transform: capitalize;
              margin-left: 2px;
              &:hover {
                color: $background;
              }
              &.active_item {
                color: $background;
              }
            }
          }
        }
      }
    }
    .menu_icon {
      svg {
        height: 19px;
        width: 19px;
        path {
          stroke: $accent;
          fill: $accent;
        }
      }
    }
  }
}
