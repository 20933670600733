@import "../../assets/styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.claims {
  margin: 0 5px;
  @media only screen and (max-width: 576px) {
    margin: 0;
  }
  &_patient {
    margin: 0 27px 30px;
    @media only screen and (max-width: 576px) {
      margin: 0 0 30px;
    }
    &_information {
      &_title {
        color: $text;
        font-family: $font-family-display;
        font-style: normal;
        font-weight: 600;
      }
    }
    &_content {
      &_text {
        color: $text;
        font-family: $font-family-text;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        &_highlight {
          font-weight: 600;
        }
      }
    }
  }
  .ant-select-selector {
    .ant-select-selection-placeholder {
      color: #818181;
      font-family: $font-family-text;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
  &_shown_fields {
    &_popup {
      border: 2px solid #d9d9d9;
      width: 20%;
      @media only screen and (max-width: 1024px) {
        width: 40%;
      }
      @media only screen and (max-width: 768px) {
        width: 50%;
      }
      @media only screen and (max-width: 576px) {
        left: 16px !important;
        right: 16px !important;
        width: calc(100% - 32px);
      }
      .ant-popover-inner {
        border-radius: 0;
        padding: 0;
        .ant-popover-inner-content {
          .ant-list {
            .ant-list-header {
              background-color: #f2eef2;
              padding: 12px;
              .ant-typography {
                font-family: $font-family-display;
                font-style: normal;
              }
            }
            .ant-list-items {
              max-height: 235px;
              overflow-y: auto;
              &::-webkit-scrollbar-track {
                border: 2px solid $secondary;
                background-color: #868686;
              }
              &::-webkit-scrollbar {
                width: 2px;
                background-color: #868686;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #414141;
                border-radius: 2px;
              }
              .ant-list-item {
                .ant-typography {
                  font-family: $font-family-display;
                  font-style: normal;
                }
              }
            }
          }
          .fields_list {
            .ant-list-items {
              .ant-list-item {
                border-block-end: 0;
                .fields {
                  &_checkbox {
                    flex-direction: row-reverse;
                    width: 100%;
                    justify-content: space-between;
                    span {
                      &:not(.ant-checkbox) {
                        &:not(.ant-checkbox-inner) {
                          width: 100%;
                          padding-left: 0;
                          font-family: $font-family-text;
                          font-style: normal;
                          font-size: 16px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &_filter_options {
    width: 100%;
    &_info_icon {
      color: $accent;
    }
    &_first_name {
      height: 40px !important;
      font-family: $font-family-text;
      font-style: normal;
      font-weight: 400;
    }
    &_last_name {
      height: 40px !important;
      font-family: $font-family-text;
      font-style: normal;
      font-weight: 400;
    }
    &_diagnosis {
      height: 40px !important;
      font-family: $font-family-text;
      font-style: normal;
      font-weight: 400;
      border: 1px solid #a8a8a8;
    }
    &_type {
      width: 100%;
      &_popup {
        border: 1px solid #a8a8a8;
        border-radius: 8px;
      }
    }
    &_custom {
      margin: 7px;
      &_button {
        width: 100% !important;
        padding: 8px 20px;
        .anticon {
          padding: 4px 0;
        }
      }
      &_date_picker {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #a8a8a8;
        background: #ffffff;
      }
    }
    &_title {
      color: #000000 !important;
      font-family: $font-family-display;
      font-size: 16px;
      font-style: normal;
      font-weight: 700 !important;
    }
    &_popup {
      border: 2px solid #d9d9d9;
      width: 20%;
      @media only screen and (max-width: 1024px) {
        width: 40%;
      }
      @media only screen and (max-width: 768px) {
        width: 50%;
      }
      @media only screen and (max-width: 576px) {
        left: 16px !important;
        right: 16px !important;
        width: calc(100% - 32px);
        overflow-y: auto;
        height: calc(100vh - 25%);
      }
      .ant-popover-inner {
        border-radius: 0;
        padding: 0;
      }
    }
  }
  &_sort_by {
    width: 100%;
  }
  &_reset_all_button {
    font-family: $font-family-display;
    font-weight: 600;
  }
  &_back_to_patients_button {
    background-color: $primary;
    font-weight: 500;
    padding: 8px 20px;
    display: flex;
    align-items: center;
  }
  &_header {
    &_title {
      color: $text !important;
      font-size: 20px !important;
      font-family: $font-family-display;
      font-weight: 700 !important;
    }
    &_btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: end;
      @media only screen and (max-width: 576px) {
        display: none;
      }
      &_export_data_btn {
        font-family: $font-family-text;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #000000;
        padding: 8px 20px;
        background: $background;
      }
      &_printtopdf_data_btn {
        padding: 8px 20px;
        font-family: $font-family-text;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #000000;
        background: $background;
      }
    }
    &_dropdown {
      &_overlay {
        .ant-dropdown-menu {
          .ant-dropdown-menu-item {
            padding: 0;
            font-family: $font-family-text;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }
  }
  &_menu {
    display: flex;
    justify-content: end;
    align-items: center;
    @media only screen and (min-width: 576px) {
      display: none;
    }
  }
  &_custom_pagination_btn {
    padding: 8px 20px;
    font-family: $font-family-text;
    font-weight: 500 !important;
    border: none;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:disabled) {
      color: $text !important;
    }
  }
  &_cell_text {
    color: $text;
    font-size: 16px;
    font-family: $font-family-text;
    font-style: normal;
    font-weight: 400;
    text-decoration: unset;
  }
  &_click_view {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &_expanded {
    &_content {
      @media only screen and (max-width: 768px) {
        max-height: 175px;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar-track {
          border: 6px solid $secondary;
          background-color: #868686;
        }
        &::-webkit-scrollbar {
          width: 16px;
          background-color: #868686;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #414141;
          border-radius: 8px;
        }
      }
    }
    &_col {
      &:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px solid #d5d5d5;
      }
    }
    &_wrapper {
      @media only screen and (max-width: 764px) {
        width: 100%;
      }
      width: 20%;
    }
    &_name {
      &_row {
        &_title {
          color: $text !important;
          font-family: $font-family-text;
          font-size: 16px !important;
          font-weight: 600 !important;
          font-style: normal;
          width: 100%;
        }
        &_cell_text {
          color: $text;
          font-family: $font-family-text;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          width: 100%;
        }
      }
    }
    &_row {
      &_title {
        color: #000000;
        font-family: $font-family-display;
        font-size: 16px;
        font-weight: 600;
      }
      &_cell_text {
        color: #000000;
        font-family: $font-family-text;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .ant-table-wrapper {
    .ant-table {
      border-radius: 8px;
      background: #ffffff;
      .ant-table-title {
        border-radius: 8px 8px 0px 0px;
        border: 1px solid #d0d0d0;
        background: #dbe2db;
        padding: 24px;
        @media only screen and (max-width: 576px) {
          padding-top: 19px;
          padding-bottom: 15px;
        }
      }
      .ant-table-container {
        table {
          border: 1px solid #d0d0d0;
          border-radius: 0px 0px 8px 8px;
          border-top: 0;
          .ant-table-thead {
            > tr {
              &:first-child {
                > th {
                  // border-bottom: 1px solid #d0d0d0;
                  @media only screen and (max-width: 576px) {
                    border: none;
                  }
                }
              }
              > th {
                &:not(:last-child) {
                  // border-right: 1px solid #d0d0d0;
                  @media only screen and (max-width: 576px) {
                    border: none;
                  }
                }
                height: 56px;
                padding: 9px 16px;
                font-family: $font-family-text;
                font-size: 16px;
                font-weight: 700;
                color: $text;
                @media only screen and (max-width: 576px) {
                  padding: 10.5px 12px;
                  height: auto;
                  border: none;
                  &:not(:first-child) {
                    text-align: center;
                  }
                }
              }
            }
          }
          .ant-table-tbody {
            > tr {
              &:not(:last-child) {
                > td {
                  // border-bottom: 1px solid #d0d0d0;
                  @media only screen and (max-width: 576px) {
                    border: none;
                  }
                }
              }
              &:last-child {
                > td {
                  border-bottom: 0;
                }
              }
              &.ant-table-expanded-row {
                .ant-table-cell {
                  background: $secondary;
                  @media only screen and (max-width: 576px) {
                    background: $secondary;
                  }
                }
                &:last-child {
                  .ant-table-cell {
                    border-radius: 0 0 8px 8px;
                  }
                }
              }
              > td {
                &:not(:last-child) {
                  // border-right: 1px solid #d0d0d0;
                  @media only screen and (max-width: 576px) {
                    border: none;
                  }
                }
                font-family: $font-family-text;
                font-size: 16px;
                font-weight: 400;
                color: $text;
                @media only screen and (max-width: 576px) {
                  padding: 10.5px 12px;
                  height: auto;
                  border: none;
                  &:not(:first-child) {
                    text-align: center;
                  }
                }
                &.ant-table-cell-row-hover {
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
    .ant-table-pagination {
      &.ant-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 32px 0 0;
        column-gap: 2px;
        @media only screen and (max-width: 576px) {
          margin-top: 10px;
          height: 71px;
        }
        .ant-pagination-prev {
          margin-right: auto;
          display: flex;
          align-items: center;
          border: none;
          @media only screen and (max-width: 576px) {
            margin-top: 100px;
            position: absolute;
            left: 0;
          }
          &.ant-pagination-disabled {
            .prev {
              border-color: transparent;
              background-color: $secondary;
              cursor: no-drop;
              &:hover {
                border-color: transparent !important;
              }
            }
          }
        }
        .ant-pagination-next {
          order: 3;
          margin-left: auto;
          display: flex;
          align-items: center;
          border: none;
          @media only screen and (max-width: 576px) {
            margin-left: auto;
            margin-top: 100px;
            position: absolute;
            right: 0;
          }
          &.ant-pagination-disabled {
            .next {
              border-color: transparent;
              background-color: $secondary;
              cursor: no-drop;
              &:hover {
                border-color: transparent !important;
              }
            }
          }
        }
        .ant-pagination-item {
          border: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px;
          font-family: $font-family-text;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          height: 40px;
          margin: 0;
          border-radius: 8px;
          @media only screen and (max-width: 576px) {
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
          }
          &.ant-pagination-item-active {
            background-color: $secondary;
            &:hover a {
              color: $text;
            }
          }
        }
      }
    }
  }
  .ant-select {
    .ant-select-arrow {
      .anticon {
        > svg {
          color: #a8a8a8;
        }
      }
    }
  }
  .ant-btn {
    &:not(:disabled) {
      &:focus-visible {
        outline: none;
      }
    }
  }
  .divider {
    height: 40px;
    border-right: 1px solid $text;
  }
  .filter-val-tag {
    background: #bfbfbf;
    font-family: $font-family-text;
    font-style: normal;
    font-size: 16px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    .ant-tag-close-icon {
      font-size: 12px;
    }
  }
}
