@import "../../../../assets/styles/colors.scss";
$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.add_user {
  &_title {
    color: $text;
    font-size: 30px;
    font-family: $font-family-display;
    font-weight: 600;
  }
  &_role_title {
    font-family: $font-family-display;
    font-weight: 600;
    font-size: 16px;
  }
  &_role_name {
    font-family: $font-family-text;
    font-size: 16px !important;
    font-weight: 400;
    color: #000000;
  }
  &_custombutton {
    font-size: 16px;
    font-family: $font-family-text;
    font-weight: 400;
  }
}
