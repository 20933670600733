@import "../../assets/styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.home {
  &_content {
    padding: 28px;
    @media only screen and (max-width: 576px) {
      padding: 0;
    }
    &_left_panel {
      padding: 19px;
      background: rgba(184, 199, 185, 0.28);
      border-radius: 12px;
      box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.08),
        0px 4px 6px -2px rgba(0, 0, 0, 0.03);
      @media only screen and (max-width: 576px) {
        border-radius: 0;
      }
    }
    &_homedoctor {
      border-radius: 12px;
      &_image {
        width: 100%;
      }
    }
    &_patient_care {
      background-color: $background;
      margin-top: 19px;
      border-radius: 12px;
      padding: 22px;
      text-align: center;
      &_title_text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        font-family: $font-family-display;
      }
      &_description_text {
        font-family: $font-family-text;
        text-align: left;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
      &_button {
        font-family: $font-family-text;
        border-radius: 8px;
        text-transform: capitalize;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 4px 16px;
        display: flex;
        align-items: center;
        margin: auto;
        border-color: transparent;
        &:not(:disabled) {
          &:hover {
            color: $accent;
            border-color: $accent;
          }
        }
      }
    }
  }
  &_welcome_text {
    font-family: $font-family-display;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px !important;
    color: #000000 !important;
    @media only screen and (max-width: 576px) {
      margin-top: 30px;
    }
  }
  &_tiles {
    @media only screen and (max-width: 576px) {
      padding: 0 15px;
    }
  }
  &_cards {
    padding: 12px;
    margin-top: 30px;
    background-color: $secondary;
    box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.08),
      0px 4px 6px -2px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    &_card_title {
      font-family: $font-family-display;
      font-style: normal;
      font-weight: 700 !important;
      font-size: 20px !important;
      line-height: 24px !important;
      text-transform: uppercase;
      color: $text !important;
    }
    &_card-description {
      font-family: $font-family-text;
      font-style: normal;
      padding-bottom: 26px;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      border-bottom: 1px dashed #cdcdcd;
      min-height: 61px;
    }
    &_card_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      &_image {
        cursor: pointer;
        svg {
          height: 24px;
          width: 24px;
        }
      }
      &_action {
        color: $accent;
        font-family: $font-family-text;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 0;
        text-transform: uppercase;
        &_icon {
          display: flex;
          align-items: center;
        }
        &:hover {
          color: $accent !important;
        }
      }
    }
  }
}
