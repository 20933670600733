$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.access_denied {
  margin-top: 157px;
  padding: 0 10px;
  @media only screen and (max-width: 576px) {
    margin-top: 25px;
    padding: 0 10px;
  }
  &_wrapper {
    border-radius: 8px;
    background-color: rgba(184, 199, 185, 0.3);
    max-width: 861px;
    width: 100%;
    height: 485px;
    margin: auto;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.03),
      0px 12px 16px -4px rgba(0, 0, 0, 0.08);
    border: 1px solid #e4e2df;
    @media only screen and (max-width: 576px) {
      width: 100%;
    }
  }
  &_img {
    text-align: center;
    margin-bottom: 47px;
    margin-top: 87px;
    @media only screen and (max-width: 576px) {
      margin-top: 40px;
      margin-bottom: 37px;
    }
  }
  &_title {
    margin-bottom: 24px;
    text-align: center;
    @media only screen and (max-width: 576px) {
      margin-bottom: 34px;
    }
    &_text {
      font-family: $font-family-display;
      font-size: 36px !important;
    }
  }
  &_text {
    font-family: $font-family-text;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 126px;
    padding: 0px 19px;
    text-transform: capitalize;
  }
}
