@import "../../../assets/styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.error_fallback {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    &_wrapper {
        border-radius: 8px;
        background-color: $accent;
        max-width: 861px;
        width: 100%;
        padding: 100px 20px;
        margin: auto;
        box-shadow:
            0px 4px 6px -2px rgba(0, 0, 0, 0.03),
            0px 12px 16px -4px rgba(0, 0, 0, 0.08);
        border: 1px solid #e4e2df;
        @media only screen and (max-width: 576px) {
            width: 100%;
            padding: 20px;
        }
    }
    &_img {
        text-align: center;
        margin-bottom: 47px;
        @media only screen and (max-width: 576px) {
            margin-top: 40px;
            margin-bottom: 37px;
        }
    }
    &_title {
        margin-bottom: 24px;
        text-align: center;
        @media only screen and (max-width: 576px) {
            margin-bottom: 34px;
        }
        &_text {
            font-family: $font-family-display;
            font-style: normal;
            color: #ffffff !important;
        }
    }
    &_text {
        font-family: $font-family-text;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 126px;
        text-transform: capitalize;
        color: #ffffff;
    }
    &_btn {
        color: $accent;
        background-color: #ffffff !important;
        &:not(:disabled) {
            &:not(.ant-btn-disabled) {
                &:hover {
                    color: $accent;
                    background-color: #ffffff;
                }
            }
        }
    }
}
