@import "../../../../assets//styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.update_userrole {
  &_title {
    font-family: $font-family-display;
    font-style: normal;
    font-weight: 600;
    font-size: 30px !important;
    line-height: 36px !important;
    color: $text !important;
    padding-bottom: 12px;
    @media only screen and (max-width: 576px) {
      font-size: 24px !important;
    }
  }

  &_select {
    width: 202px;
    @media only screen and (max-width: 576px) {
      width: 100%;
      display: block;
    }
  }
  &_title_text {
    font-family: $font-family-display;
    color: $text;
  }
  &_description_text {
    font-family: $font-family-text;
    font-size: 16px;
    color: $text;
  }
}
