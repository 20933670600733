@import "../../../assets/styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.admin_dashboard_box {
    &_border {
        border: 1px solid #dbdbdb;
        border-radius: 8px;
    }
    &_left_menu {
        padding: 20px;
        .list_item {
            border-block-end: none;
            padding-bottom: 0;
            padding: 8px 0 0;
            &_link {
                font-family: $font-family-text;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $text !important;
                text-decoration: none;
                &:hover,
                &.active {
                    font-weight: 700;
                }
            }
        }
    }
    &_title {
        font-family: $font-family-display;
        font-style: normal;
        font-weight: 600;
        font-size: 30px !important;
        line-height: 36px !important;
        color: $text !important;
        @media only screen and (max-width: 576px) {
            font-size: 24px !important;
        }
    }
}
.admin_dashboard_content_box {
    &_border {
        border: 1px solid #dbdbdb;
        border-radius: 8px;
    }
    &_content {
        padding: 21px 24px;
    }
}
