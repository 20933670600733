@import "../../../assets/styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.uploaded_files {
  margin: 0 5px;
  @media only screen and (max-width: 576px) {
    margin: 0;
  }
  &_filter_options {
    width: 100% !important;
    &_custom {
      margin: 7px;
      &_button {
        width: 100% !important;
        padding: 8px 15px;
        .anticon {
          padding: 4px 0;
        }
      }
      &_date_picker {
        width: 100% !important;
        border-radius: 8px;
        border: 1px solid #a8a8a8;
        background: #ffffff;
      }
    }
    &_title {
      color: #000000 !important;
      font-family: $font-family-display;
      font-size: 16px;
      font-style: normal;
      font-weight: 700 !important;
    }
    &_popup {
      border: 2px solid #d9d9d9;
      width: 15%;
      @media only screen and (max-width: 1024px) {
        width: 30%;
      }
      @media only screen and (max-width: 768px) {
        width: 40%;
      }
      @media only screen and (max-width: 576px) {
        width: calc(100% - 32px);
        left: 16px !important;
        right: 16px !important;
      }
      .ant-popover-inner {
        border-radius: 0;
        padding: 0;
      }
    }
  }
  .ant-select-selector {
    .ant-select-selection-placeholder {
      color: #818181;
      font-family: $font-family-text;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
  &_reset_all_button {
    font-family: $font-family-display;
    font-weight: 600;
  }
  &_report-tabs {
    > .ant-tabs-nav {
      .ant-tabs-tab {
        background: rgba(184, 199, 185, 0.02);
        border: 1px solid $primary;
        &:hover {
          color: $accent;
        }
      }
      .ant-tabs-tab-active {
        color: #ffffff;
        background: $accent;
        border: 1px solid $accent;
        border-bottom-color: $accent !important;
        .ant-tabs-tab-btn {
          color: #ffffff !important;
        }
      }
    }
    .ant-tabs-tab-btn {
      &:active {
        color: #ffffff;
      }
      &:focus:not(:focus-visible) {
        color: $accent;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
  &_header {
    &_title {
      color: $text !important;
      font-size: 20px !important;
      font-family: $font-family-display;
      font-weight: 700 !important;
    }
    &_btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: end;
      @media only screen and (max-width: 576px) {
        display: none;
      }
      &_export_data_btn {
        font-family: $font-family-text;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #000000;
        padding: 8px 20px;
        background: $background;
      }
    }
    &_dropdown {
      &_overlay {
        .ant-dropdown-menu {
          .ant-dropdown-menu-item {
            padding: 0;
            font-family: $font-family-text;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }
  }
  &_menu {
    display: flex;
    justify-content: end;
    align-items: center;
    @media only screen and (min-width: 576px) {
      display: none;
    }
  }
  &_custom_pagination_btn {
    padding: 8px 20px;
    font-family: $font-family-text;
    font-weight: 500 !important;
    border: none;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:disabled) {
      color: $text !important;
    }
  }
  &_search {
    width: 100%;
    font-family: $font-family-text;
    .ant-select-arrow {
      font-size: 14px;
    }
    .ant-select-clear {
      width: 14px;
      height: 14px;
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
    }
    &_item {
      border: 1px solid #a8a8a8;
      border-radius: 8px;
      min-width: max-content !important;
      .narrow_down,
      .ant-select-item-empty {
        color: #000000;
        font-family: $font-family-text;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 11px;
        padding: 0;
      }
    }
  }
  .ant-table-wrapper {
    .ant-table {
      border-radius: 8px;
      background: #ffffff;
      .ant-table-title {
        border-radius: 8px 8px 0px 0px;
        border: 1px solid #d0d0d0;
        background: #dbe2db;
        padding: 24px;
        @media only screen and (max-width: 576px) {
          padding-top: 19px;
          padding-bottom: 15px;
        }
      }
      .ant-table-container {
        table {
          border: 1px solid #d0d0d0;
          border-radius: 0px 0px 8px 8px;
          border-top: 0;
          .ant-table-thead {
            > tr {
              > th {
                border-bottom: 1px solid #d0d0d0;
                &:not(:last-child) {
                  border-right: 1px solid #d0d0d0;
                }
                height: 56px;
                padding: 16px 24px;
                font-family: $font-family-text;
                font-size: 16px;
                font-weight: 700;
                color: $text;
                &:first-child {
                  width: 6%;
                }
                &:nth-child(2) {
                  width: 47%;
                }
                &:nth-child(3) {
                  width: 47%;
                }
                @media only screen and (max-width: 576px) {
                  padding: 10.5px 12px;
                  height: auto;
                  &:not(:first-child) {
                    text-align: center;
                  }
                }
              }
            }
          }
          .ant-table-tbody {
            > tr {
              border-bottom: 1px solid #d0d0d0;
              &:last-child {
                > td {
                  border-bottom: 0;
                }
              }
              &.ant-table-expanded-row {
                .ant-table-cell {
                  background: $secondary;
                }
                &:last-child {
                  .ant-table-cell {
                    border-radius: 0 0 8px 8px;
                  }
                }
              }
              > td {
                border-bottom: 1px solid #d0d0d0;
                height: 56px;
                padding: 16px 24px;
                font-family: $font-family-text;
                font-size: 16px;
                color: $text;
                .ant-typography {
                  font-family: $font-family-text;
                  font-size: 16px;
                }
                &:not(:last-child) {
                  border-right: 1px solid #d0d0d0;
                }
                &:first-child {
                  width: 6%;
                }
                &:nth-child(2) {
                  width: 47%;
                }
                &:nth-child(3) {
                  width: 47%;
                }
                @media only screen and (max-width: 576px) {
                  padding: 10.5px 12px;
                  height: auto;
                  &:not(:nth-child(2)) {
                    text-align: center;
                  }
                }
                &.ant-table-cell-row-hover {
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
    .ant-table-pagination {
      &.ant-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 32px 0 0;
        column-gap: 2px;
        @media only screen and (max-width: 576px) {
          margin-top: 10px;
          height: 71px;
        }
        .ant-pagination-prev {
          margin-right: auto;
          display: flex;
          align-items: center;
          border: none;
          @media only screen and (max-width: 576px) {
            margin-top: 100px;
            position: absolute;
            left: 0;
          }
          &.ant-pagination-disabled {
            .prev {
              border-color: transparent;
              background-color: $secondary;
              cursor: no-drop;
              &:hover {
                border-color: transparent !important;
              }
            }
          }
        }
        .ant-pagination-next {
          order: 3;
          margin-left: auto;
          display: flex;
          align-items: center;
          border: none;
          @media only screen and (max-width: 576px) {
            margin-left: auto;
            margin-top: 100px;
            position: absolute;
            right: 0;
          }
          &.ant-pagination-disabled {
            .next {
              border-color: transparent;
              background-color: $secondary;
              cursor: no-drop;
              &:hover {
                border-color: transparent !important;
              }
            }
          }
        }
        .ant-pagination-item {
          border: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px;
          font-family: $font-family-text;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          height: 40px;
          margin: 0;
          border-radius: 8px;
          @media only screen and (max-width: 576px) {
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
          }
          &.ant-pagination-item-active {
            background-color: $secondary;
            &:hover a {
              color: $text;
            }
          }
        }
      }
    }
  }
  .ant-select {
    .ant-select-arrow {
      .anticon {
        > svg {
          color: #a8a8a8;
        }
      }
    }
  }
  .ant-btn {
    &:not(:disabled) {
      &:focus-visible {
        outline: none;
      }
    }
  }
  .filter-val-tag {
    background: #bfbfbf;
    font-family: $font-family-text;
    font-style: normal;
    font-size: 16px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    .ant-tag-close-icon {
      font-size: 12px;
    }
  }
}
