@import "../../../assets/styles/colors.scss";

$font-family-display: "SF-Pro-Display";
$font-family-text: "SF-Pro-Text";

.analysis {
  margin: 0 15px;

  @media only screen and (max-width: 576px) {
    margin: 0;
  }

  &_title {
    font-size: 24px;
    font-family: $font-family-display;
  }

  &_box {
    border: 2px solid #d0cece;
    border-radius: 8px;

    &_text {
      font-family: $font-family-text;
      font-style: normal;
      font-size: 14px;
    }

    &_filter {
      margin: 15px;

      &_title {
        color: #000000;
        font-family: $font-family-display;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }

      &_custom {
        &_button {
          width: 100% !important;
          padding: 8px 20px;
          font-family: $font-family-text;
          font-size: 16px;
        }
      }

      &_options {
        &_popup {
          .ant-select-item-option-content {
            overflow: unset;
            white-space: break-spaces;
            text-overflow: unset;
          }
        }
      }
    }

    &_tabs {
      > .ant-tabs-nav {
        .ant-tabs-tab {
          background: rgba(184, 199, 185, 0.02);
          border: 1px solid $primary;

          &:hover {
            color: $accent;
          }
        }

        .ant-tabs-tab-active {
          color: #ffffff;
          background: $accent;
          border: 1px solid $accent;
          border-bottom-color: $accent !important;

          .ant-tabs-tab-btn {
            color: #ffffff !important;
          }
        }
      }

      .ant-tabs-tab-btn {
        &:active {
          color: #ffffff;
        }

        &:focus:not(:focus-visible) {
          color: $accent;
        }

        &:focus-visible {
          outline: none;
        }
      }
    }
  }

  &_view {
    &_box {
      border-radius: 3px;
      padding: 5px 0;
      font-weight: 700;
      max-width: 75%;
      width: 100%;
      margin: auto;
      text-align: center;
      font-size: 16px;
      font-family: $font-family-text;
    }

    &_table {
      &_title {
        &_export_data_btn {
          font-family: $font-family-text;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          color: #000000;
          padding: 8px 20px;
          background: $background;
        }
      }
      &_completed {
        background-color: #ffd9b2;
      }
      &_gaptotarget {
        background-color: #f1f1f1;
      }
      &_total {
        background-color: #efe9f5;
      }
      &_custom_pagination_btn {
        padding: 8px 20px;
        font-family: $font-family-text;
        font-weight: 500 !important;
        border: none;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:disabled) {
          color: $text !important;
        }
      }
      &_item-middle {
        padding: 5px 0;
        margin: auto;
      }

      .ant-table-wrapper {
        .ant-table {
          border-radius: 8px;
          background: #ffffff;

          .ant-table-title {
            border-radius: 8px 8px 0px 0px;
            border: 1px solid #d0d0d0;
            background: #dbe2db;
            padding: 15px;

            @media only screen and (max-width: 576px) {
              padding-top: 19px;
              padding-bottom: 15px;
            }
          }

          .ant-table-container {
            table {
              border: 1px solid #d0d0d0;
              border-radius: 0px 0px 8px 8px;
              border-top: 0;

              .ant-table-thead {
                > tr {
                  &:first-child {
                    > th {
                      border-bottom: 1px solid #d0d0d0;

                      @media only screen and (max-width: 576px) {
                        border: none;
                      }
                    }
                  }

                  > th {
                    &:not(:last-child) {
                      @media only screen and (max-width: 576px) {
                        border: none;
                      }
                    }

                    height: 56px;
                    padding: 15px;
                    font-family: $font-family-text;
                    font-size: 16px;
                    font-weight: 700;
                    color: $text;

                    @media only screen and (max-width: 576px) {
                      padding: 10.5px 12px;
                      height: auto;
                      border: none;

                      &:not(:first-child) {
                        text-align: center;
                      }
                    }
                  }
                }
              }

              .ant-table-tbody {
                .ant-progress-text {
                  font-weight: 700;
                  font-family: $font-family-text;
                  font-size: 16px;
                  margin-inline-start: 4px;
                }
                > tr > td {
                  .ant-row {
                    .ant-progress-inner {
                      border-radius: 3px;

                      .ant-progress-bg {
                        height: 18px !important;
                        border-radius: 3px;
                        background-color: rgb(255, 151, 0);
                      }
                    }
                  }
                  .ant-row {
                    .border-yoy {
                      .ant-progress-inner {
                        .ant-progress-bg {
                          border: 1px solid rgb(255, 151, 0);
                        }
                      }
                    }
                  }
                }

                > tr {
                  &:not(:last-child) {
                    > td {
                      border-bottom: 1px solid #d0d0d0;

                      @media only screen and (max-width: 576px) {
                        border: none;
                      }
                    }
                  }

                  &:last-child {
                    > td {
                      border-bottom: 0;
                    }
                  }

                  &.ant-table-expanded-row {
                    .ant-table-cell {
                      padding: 10px 0;

                      @media only screen and (max-width: 576px) {
                        background: $secondary;
                      }
                    }

                    &:last-child {
                      .ant-table-cell {
                        border-radius: 0 0 8px 8px;
                      }
                    }
                  }

                  > td {
                    &:not(:last-child) {
                      @media only screen and (max-width: 576px) {
                        border: none;
                      }
                    }

                    padding: 15px;
                    font-family: $font-family-text;
                    font-size: 16px;
                    font-weight: 400;
                    color: $text;

                    span {
                      font-family: $font-family-text;
                      font-size: 16px;
                      font-weight: 400;
                      color: $text;
                    }

                    @media only screen and (max-width: 576px) {
                      padding: 10.5px 12px;
                      height: auto;
                      border: none;

                      &:not(:first-child) {
                        text-align: center;
                      }
                    }

                    &.ant-table-cell-row-hover {
                      background: transparent;
                    }
                  }
                }
              }
            }
          }
        }
        .ant-table-pagination {
          &.ant-pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 15px 0 0;
            column-gap: 2px;
            @media only screen and (max-width: 576px) {
              margin-top: 10px;
              height: 71px;
            }
            .ant-pagination-prev {
              margin-right: auto;
              display: flex;
              align-items: center;
              border: none;
              @media only screen and (max-width: 576px) {
                margin-top: 100px;
                position: absolute;
                left: 0;
              }
              &.ant-pagination-disabled {
                .prev {
                  border-color: transparent;
                  background-color: $secondary;
                  cursor: no-drop;
                  &:hover {
                    border-color: transparent !important;
                  }
                }
              }
            }
            .ant-pagination-next {
              order: 3;
              margin-left: auto;
              display: flex;
              align-items: center;
              border: none;
              @media only screen and (max-width: 576px) {
                margin-left: auto;
                margin-top: 100px;
                position: absolute;
                right: 0;
              }
              &.ant-pagination-disabled {
                .next {
                  border-color: transparent;
                  background-color: $secondary;
                  cursor: no-drop;
                  &:hover {
                    border-color: transparent !important;
                  }
                }
              }
            }
            .ant-pagination-item {
              border: transparent;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 12px;
              font-family: $font-family-text;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              height: 40px;
              margin: 0;
              border-radius: 8px;
              @media only screen and (max-width: 576px) {
                min-width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 400;
              }
              &.ant-pagination-item-active {
                background-color: $secondary;
                &:hover a {
                  color: $text;
                }
              }
            }
          }
        }
      }
    }
  }
  &_chart {
    &_percent {
      width: 100%;
    }
  }
}
